import { Production } from "../configuration/system";

export class RequestRepository {
  async login(username, password) {
    try {
      let resp = await fetch(`${Production.get_api_url()}dashboard/login`, {
        method: "POST",
        body: JSON.stringify({ username: username, password: password }),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      return await resp.json();
    } catch (e) {
      console.error("Error logging in", e);
    }
  }
  async logged(token) {
    try {
      let resp = await fetch(`${Production.get_api_url()}dashboard/login`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "XSRF-TOKEN": token
        },
        credentials: "include",
      });

      return await resp.json();
    } catch (e) {
      console.error("Error logging in", e);
    }
  }
  async logout() {
    try {
      let resp = await fetch(`${Production.get_api_url()}dashboard/logout`, {
        method: "GET",
        headers: {
          "Content-Type": "text/plain",
        },
        credentials: "include",
      });
      return resp;
    } catch (e) {
      console.error("ERROR LOGGING OUT!", e);
    }
  }
  async get_token(){
    try{
      let resp = await fetch(`${Production.get_api_url()}dashboard/get_token`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include"
      });
      return resp.json();
    } catch(e){
      console.error("Error token",e); // should probably return some object with error 
    }
  }
  async get_all_questions(api_key) {
    try {
      let resp = await fetch(`${Production.get_api_url()}question/get_all_questions`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
//          "Authorization": api_key
        },
        credentials: "include",
      });

      return await resp.json();
    } catch (e) {
      console.error("Error logging in", e);
    }
  }
  async add_question(question) {
    try {
      let resp = await fetch(`${Production.get_api_url()}question/add_question`, {
        method: "POST",
        body: JSON.stringify(question),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      return await resp.json();
    } catch (e) {
      console.error("Error logging in", e);
    }
  }
}
RequestRepository.default = new RequestRepository();
