import React, { useEffect, useState, useRef, useContext } from "react";
import { Form, Alert } from "react-bootstrap";
import { RequestRepository as Request } from "../infrastructure/repository/RequestRepository";
import { Redirect } from "wouter";
import { UserContext } from "./context/UserContext";

export default function Login() {
  let [redirect, setRedirect] = useState(false);
  let [{ username: uname }, setUser] = useContext(UserContext);
  let [errorMsg, setError] = useState({ status: false, message: "" });
  let [credentials, setCredentials] = useState({ username: "", password: "" });
  let userRef = useRef(null); // Can use those to clean input value
  let passRef = useRef(null); // Can use those to clean input value
  let request = Request.default;

  let submit = async function (e) {
    e.preventDefault();
    console.log("submitting");
    let { username, password } = credentials;
    // this is not needed since i am using html5 form
    if (!username || !password) {
      return;
    }
    try {
      let req = await request.login(username, password);
      if (req.success) {
        // setUser context for currently logged in
        setUser({ username: username });
        // redirect to dashboard
        setRedirect(true);
        return;
      }
      setError({ status: true, message: req.error });
      return;
    } catch (e) {
      console.error("error: ", e);
    }
  };

  useEffect(() => {
    //set body align class to align login, Gynvael check this because I had to switch styles in useEffect
    document.body.classList.add("align");
  }, []);


  return (
    <div className="grid">
      <Form onSubmit={(e) => submit(e)} className="form login">
        <div className="form__field">
          <label for="login__username">
            <span className="hidden">Username</span>
          </label>
          <input
            onChange={(e) => {
              if (errorMsg.status) {
                setError({ status: false });
              }
              setCredentials((prevState) => {
                return {
                  username: e.target.value,
                  password: prevState.password,
                };
              });
            }}
            ref={userRef}
            id="login__username"
            type="text"
            name="username"
            className="form__input"
            placeholder="Username"
            required
          />
        </div>

        <div className="form__field">
          <label for="login__password">
            <span className="hidden">Password</span>
          </label>
          <input
            onChange={(e) => {
              if (errorMsg.status) {
                setError({ status: false });
              }
              setCredentials((prevState) => {
                return {
                  username: prevState.username,
                  password: e.target.value,
                };
              });
            }}
            ref={passRef}
            id="login__password"
            type="password"
            name="password"
            className="form__input"
            placeholder="Password"
            required
          />
        </div>

        <div className="form__field">
          <input onKeyDown={(e) => submit(e)} type="submit" value="Login" />
        </div>
        {errorMsg.status ? (
          <div className="form__field">
            <Alert variant={"danger"}>{errorMsg.message}</Alert>
          </div>
        ) : (
          <React.Fragment />
        )}
      </Form>
      {redirect ? <Redirect to="/dashboard" /> : <></>}
    </div>
  );
}
