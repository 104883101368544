export const Development = {
  service_url: "http://localhost:4000",
  api: "/api/",
  get_api_url: () => {
    return `${Development.service_url}${Development.api}`;
  },
};


export const Production = {
  service_url: "http://localhost:5000",
  api: "/api/",
  get_api_url: () => {
    return `${Production.service_url}${Production.api}`;
  },
};
