import React from 'react';

export const UserContext = React.createContext({
  username: "",
});

export function useUserHook() {
  let [user, setUser] = React.useState({username: ""});
  return [user, setUser];
}
