import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Switch, Route, Redirect, useLocation } from "wouter";
import React, {useEffect, useState} from "react";
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import {RequestRepository as Request} from "./infrastructure/repository/RequestRepository";
import {
  UserContext,
  useUserHook,
} from "./components/context/UserContext";
import NavbarDashboard from "./components/Navbar";

function App() {
  let [user, setUser] = useUserHook();
  let [location, setLocation] = useLocation();
  let request = Request.default;
  /* Make fetch for get dashboard to set location to it if session is present already so no need for logging in again  */
  useEffect(()=>{
    (async function(){
     // get request login and check for token 
       let token = await request.get_token();
       if(token.success){
         let logged = await request.logged(token.success);
         if(logged.success){
            // redirect
            let loggedUser = logged.success;
            setUser({"username":loggedUser})
            setLocation("/dashboard");
            return;
         }
       }
       setLocation("/");
    })()
  },[])
  return (
    <div className="App">
      <UserContext.Provider value={[user, setUser]}> 
        <Switch>
          <Route path="/" component={Login}/>
          {user.username?(
            <Route path="/dashboard" component={Dashboard}/>
          ):(
           <></>
          )}
         <Route path="/:rest*">
         {(params) =>{
           return <Redirect to="/" />;
         }}
         </Route>
        </Switch>
      </UserContext.Provider> 
    </div>
  );
}

export default App;
