import React, { useContext } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { RequestRepository as Request } from "../infrastructure/repository/RequestRepository";
import { useLocation } from "wouter";
import { UserContext } from "./context/UserContext";

export default function NavbarDashboard() {
  let request = Request.default;
  let [location, setLocation] = useLocation();
  let [{ username }, setUser] = useContext(UserContext);

  let logout = async () => {
    try {
      let resp = await request.logout();
      if (resp.status === 200) {
        setLocation("/");
        return;
      }
    } catch (e) {
      console.error("error logging out", e);
    }
  };

  return (
    <Navbar bg="light" expand="md">
      {/*<Container> */}
      <Navbar.Brand href="#home">Gynvael's Stream Dashboard</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse className="responsive-navbar-nav">
        <Nav className="ml-auto">
          <NavDropdown align="end" title={username} id="nav-add">
            <NavDropdown.Item
              onClick={(e) => {
                logout();
              }}
            >
              Logout
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
      {/*</Container> */}
    </Navbar>
  );
}
