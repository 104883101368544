import React, { useEffect } from "react";
import {
  Card,
  ListGroup,
  Form,
  Container,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import DashboardTable from "./Table";
import moment from "moment";
import Moment from "react-moment";
import { RequestRepository as Request } from "../infrastructure/repository/RequestRepository.js";
import NavbarDashboard from "./Navbar";

export default function Dashboard() {
  let [questions, setQuestions] = React.useState([]);
  let [newQuestion, addQuestion] = React.useState({
    text: "",
    author: "",
    state: "approved",
    source: "moderators",
  });
  let [render, setRender] = React.useState(false);
  let authorInput = React.useRef(null);
  let questionInput = React.useRef(null);
  let request = Request.default;

  let addApproved = async (question) => {
    try {
      let resp = await request.add_question({ question: question });
      if (resp.questions.length > 0) {
        setQuestions(resp.questions);
      }
    } catch (e) {
      console.error("Error adding approved question: ", e);
    }
  };

  let questionHandler = async (question, state) => {
    question.state = state;
    addApproved(question);
  };

  let renderQuestions = (state) => {
    if (state === "urgent") {
      return questions.filter(
        (q) => q.state === state || q.state === "approved"
      );
    }
    return questions.filter((q) => q.state === state);
  };

  let generateButtons = React.useCallback((cell, type) => {
    let question = cell.cell.row.original;
    let { state } = cell.cell.row.original;
    return (
      <>
        <Button
          onClick={(e) => {
            questionHandler(question, "approved");
          }}
          disabled={state === "approved" ? true : false}
        >
          {" "}
          Approved{" "}
        </Button>
        <Button
          onClick={(e) => {
            questionHandler(question, "urgent");
          }}
          disabled={state === "urgent" ? true : false}
        >
          {" "}
          Urgent{" "}
        </Button>
        <Button
          onClick={(e) => {
            questionHandler(question, "rejected");
          }}
          disabled={state === "rejected" ? true : false}
        >
          {" "}
          Rejected
        </Button>
      </>
    );
  },[]);

  const cols = React.useMemo(() => {
    return (type) => [
      {
        Header: "Autor",
        accessor: "author",
      },
      {
        Header: "Pytanie",
        accessor: "text",
      },
      {
        Header: "Source",
        accessor: "source",
      },
      {
        Header: "Time",
        accessor: "time",
        Cell: (cell) => {
          let t = moment.unix(cell.cell.row.original.time);
          return (
            <>
              <Moment interval={1000} fromNow>
                {t}
              </Moment>
            </>
          );
        },
      },
      {
        Header: "Opcije",
        accessor: "opcije",
        Cell: (cell) => generateButtons(cell), //generateButtons(cell, type)
      },
    ];
  }, [generateButtons]);

  useEffect(() => {
    document.body.classList.remove("align");
    document.getElementsByClassName("App")[0].style.width = "100%";
    console.log("called once");
    (async function () {
      try {
        let resp = await request.get_all_questions();
        console.log("Resp get all questions: ", resp.questions);
        if (resp.questions.length > 0) {
          setQuestions(resp.questions);
        }
      } catch (e) {
        console.error("error receiving all questions", e);
      }
    })();
    //setQuestions(data);
  }, []);

  // FETCH EVERY 10 seconds
  useEffect(() => {
    let fetchPeriodically = setInterval(() => {
      (async () => {
        try {
          let resp = await request.get_all_questions();
          console.log("fetching every 10 seconds: ", resp.questions);
          if (resp.questions.length > 0) {
            setQuestions(resp.questions);
          }
        } catch (e) {
          console.error("error refetching data: ", e);
        }
      })();
    }, 10000);
    return () => clearInterval(fetchPeriodically);
  }, []);

  useEffect(() => {
    //console.log("questions changed: ", questions);
    if (questions.length > 0) {
      //console.log("rerender questions")
      setRender(true);
    }
  }, [questions]);

  return (
    <>
      <NavbarDashboard />
      <Container>
        {render ? (
          <>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Author</Form.Label>
                <Form.Control
                  ref={authorInput}
                  onChange={(e) =>
                    addQuestion((prevQuestion) => {
                      return { ...prevQuestion, author: e.target.value };
                    })
                  }
                  type="text"
                  placeholder="Author"
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Question</Form.Label>
                <Form.Control
                  ref={questionInput}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      addApproved(newQuestion);
                      questionInput.current.value = "";
                      authorInput.current.value = "";
                    }
                  }}
                  onChange={(e) =>
                    addQuestion((prevQuestion) => {
                      return { ...prevQuestion, text: e.target.value };
                    })
                  }
                  as="textarea"
                  rows={3}
                />
              </Form.Group>
              <Button
                onClick={(e) => {
                  console.log("ref", questionInput.current.value);
                  addApproved(newQuestion);
                  questionInput.current.value = "";
                  authorInput.current.value = "";
                }}
              >
                Dodaj
              </Button>
            </Form>
            <h2>New Questions</h2>
            <DashboardTable
              columns={cols()}
              data={renderQuestions("new")}
            />{" "}
            {/* aur */}
            <h2>Approved and Urgent Questions</h2>
            <DashboardTable
              columns={cols()}
              data={renderQuestions("urgent")}
            />{" "}
            {/* aur */}
            <h2>Rejected Questions</h2>
            <DashboardTable
              columns={cols()}
              data={renderQuestions("rejected")}
            />{" "}
            {/* au */}
          </>
        ) : (
          <React.Fragment><h1>NO QUESTIONS YET! :( </h1></React.Fragment>
        )}
      </Container>
      <Row>
        <Col>
          <h2>PL</h2>
          <Card style={{ width: "18rem" }}>
            <ListGroup variant="flush">
              <ListGroup.Item>
                {" "}
                <a href="https://gynvael.live/faq_pl.html" rel="noreferrer" target="_blank">
                  https://gynvael.live/faq_pl.html{" "}
                </a>{" "}
              </ListGroup.Item>
              <ListGroup.Item>
                {" "}
                <a href="https://github.com//gynvael/stream" rel="noreferrer" target="_blank">
                  {" "}
                  https://github.com//gynvael/stream{" "}
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                {" "}
                <a href="https://youtube.com/@GynvaelColdwind" rel="noreferrer" target="_blank">
                  {" "}
                  https://youtube.com/@GynvaelColdwind{" "}
                </a>{" "}
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
        <Col>
          <h2>EN</h2>
          <Card style={{ width: "18rem" }}>
            <ListGroup variant="flush">
              <ListGroup.Item>
                {" "}
                <a href="https://gynvael.live/faq_en.html" rel="noreferrer" target="_blank">
                  {" "}
                  https://gynvael.live/faq_en.html
                </a>{" "}
              </ListGroup.Item>
              <ListGroup.Item>
                {" "}
                <a href="https://github.com/gynvael/stream-en" rel="noreferrer" target="_blank">
                  {" "}
                  https://github.com/gynvael/stream-en{" "}
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                {" "}
                <a href="https://youtube.com/@GynvaelEN" rel="noreferrer" target="_blank">
                  {" "}
                  https://youtube.com/@GynvaelEN{" "}
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                {" "}
                <a href="https://infosec.exchange/@gynvael" rel="noreferrer" target="_blank">
                  {" "}
                  https://infosec.exchange/@gynvael{" "}
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                {" "}
                <a href="https://twitter.com/gynvael" rel="noreferrer" target="_blank">
                  {" "}
                  https://twitter.com/gynvael{" "}
                </a>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
        <Col>
          <h2>Common</h2>
          <Card style={{ width: "18rem" }}>
            <ListGroup variant="flush">
              <ListGroup.Item>
                {" "}
                <a href="https://gynvael.coldwind.pl/discord" rel="noreferrer" target="_blank">
                  {" "}
                  https://gynvael.coldwind.pl/discord{" "}
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                {" "}
                <a href="https://gynvael.coldwind.pl/" rel="noreferrer" target="_blank">
                  {" "}
                  https://gynvael.coldwind.pl/{" "}
                </a>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </>
  );
}
